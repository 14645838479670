.landing-page {
   text-align: center;
}

.landing-text {
   margin: 1em;
   color: white;
}

.landing-btn {
   height: 54%;
   min-width: 7em;
}

.bom-text {
   color: white;
}

.background {
   background-image: linear-gradient(#08335E99, #08335E99),  url("../../assets/science-in-hd-pAzSrQF3XUQ-unsplash.jpg");
   background-repeat: no-repeat;
   background-size: cover;
}

.search-area {
   
   height: 6.5em;
   display: flex;
   justify-content: center;
   align-items: center;

   .search-bar {
      width: 50%;
   }

   .search-bar-input {
      color: black;
      background-color: white;
   }

   .search-btn {
      height: 100%;
      width: 100%;
      color:white;
      background-color: $as-blue;
      border-radius: 0px;
   }

   .search-btn:hover {
      background-color: $as-dark-blue;
      color: white;
   }

   .input-root {
      color: inherit;
   }

   .input-input {
      width: 100%;
   }
}

.spacer {
   margin: 4em;
}

.spacer-small {
   margin: 2em;
}

.spacer-large {
   margin: 8em;
}

.underlined-clickable {
   text-decoration: underline;
   cursor: pointer;
   border: none;
   background-color: transparent;
   padding: 0;
}

.info-card {
   .info-card-img {
      margin: 1.2em;
   }
   
   .info-card-name {
      color: $as-blue;
   }
}

.landing-category-btn {
      display: flex;
      align-items: center;
      height: 100%;
      float: left;
      background-color: #B6C8D9;
      border-radius: 0px;
      color: $as-dark-blue;
}