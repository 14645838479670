$item-transition-speed: 0.2s;
$item-height: 48em;

.default-text {
   font-family: Arial, Helvetica, sans-serif;
   color: var(--text-grey);
   font-weight: 500;
   margin: auto 0;
   padding-left: 0.2em;
}

.paper-title {
   color: $as-grey-blue;
   font-weight: 800;
}

.list-wrapper {
  
  .list-display {
    margin: 2em 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    
    .item-display {
      height: 99%;
      width: 100%;
      border: $item-border;
      margin: 0.3em;
      padding: 0.3em;
      transition: $item-transition-speed;
      cursor: pointer;
      background-color: $color-background;
      
      display: flexbox;

      &:hover {
        box-shadow: $box-shadow-full;
      }
      
      .item-display-image {
        height: $item-height - 10em;
        width: 24em;
        
        border: .05em solid $color-light-grey-border;
        border-radius: 1em;
        
        overflow: hidden;
        vertical-align: middle;
        text-align:center;
        
        padding: 0.5em;
        margin: auto;
      }

      .card-actions {
        justify-content: center;
      }

      .item-display-subtitle-info {	
        display: block;	
      }
    }
  }
}

.table-wrapper {
   .table-display {
      .table-size {
         max-width: 60em;
         min-width: 20em;
      }
      .table-cell-image-content {
         cursor: pointer;
      
         .table-cell-image {
            cursor: pointer;
         }
      }
   }
}

.filter-side-column {
   border-radius: 0.2em;
   padding-left:  0.2em;
   padding-right:  0.2em;
   padding-top: 0em;
   color: $as-dark-blue;

   .filter-input {
      color: $as-dark-blue;
   }
}

.pagination {
   display: flex;
   justify-content: center;
}

#graph-holder {
   .axis-grid line {
      stroke: #def
   }

   .legend-instance {
      display: flex;
      align-items: center;
      text-align: 'center';
   }

   .legend-image-container {
      height: 50px;
      width: 100px;
      margin-left: 5px;
    }
    
    .legend-image-container img {
      width: 100%;
      height: 100%;
    }
    
    .legend-image {
      object-fit: contain;
    }
}

.item-details-image {
   min-width: 250px;
   max-width: 400px;
   width: 100%;
}